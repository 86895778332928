<app-navbar></app-navbar>
<!-- Header -->
<header>
  <div class="container">
    <div id="user-success"></div>
    <div class="row">
      <div class="col-lg-12">
        <img class="img-responsive" src="assets/icons/logo_circle_144.png"/>
        <div class="intro-text" style="padding-bottom:20px">
          <span class="name">Find real-time food and drink specials near you</span>
          <hr class="star-primary">
          <span class="skills">Speckles is a mobile application that helps you and your friends save money by aggregating all the food specials and happy hours near you and presenting them to you in an easy-to-use free application.</span>
        </div>
        <a href='https://play.google.com/store/apps/details?id=com.specials.mobile.android.specialsgradle&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
          <img alt='Get it on Google Play' src='https://res.cloudinary.com/doib3j4ca/image/upload/v1477115144/google_play_badge__unhnmj.png'/>
        </a>
        <a href='https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1342054150&mt=8' target="_blank">
          <img alt='Get it on the App Store' src='https://res.cloudinary.com/doib3j4ca/image/upload/c_scale,w_275/v1557977640/Download_on_the_App_Store_Badge_US-UK_blk_092917_qsyady.png'/>
        </a>
      </div>
    </div>
  </div>
</header>

<!-- How It Works Section -->
<section id="howitworks" class="howitworks">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h2>How It Works</h2>
        <hr class="star-light-howitworks">
      </div>
    </div>
    <div class="row">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/rFGsn8wl2w0" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</section>

<!-- About Section -->
<section class="success" id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h2>About</h2>
        <hr class="star-light">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-lg-offset-2">
        <p>Speckles is a free, location-aware, photo-based Android app that helps you find specials,
          discounts and deals that are posted by businesses near you.
          Think of it as a noticeboard for discounts: Businesses post pictures of their deals,
          Speckles users can view and share them. For now we're only in the St Louis,MO and Philadelphia,PA areas
          but we'll be expanding to other regions soon.</p>
      </div>
      <div class="col-lg-4">
        <p>Tired of going to the same old happy hours? Want to find a nice lunch special? Hop on Speckles! You'll save money while at the same time discovering new spots around you. If you're a business, Speckles is the perfect platform for you to post your specials because we'll expose you to a wider audience. Speckles also offers analytics features that help you gain insights about your posts.</p>
      </div>
    </div>
  </div>
</section>

<!-- Register Section -->
<section id="register">
  <input id="apiUrl" type="hidden" value="{{apiUrl}}"/>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h2>Are you a business? Sign up with us to get started</h2>
        <hr class="star-primary">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-lg-offset-2">
        <form [formGroup]="registrationForm" name="registrationForm" id="registration-form" role="form" (ngSubmit)=onSubmit()>
          <div class="row control-group">
            <div id="businessNameRow" class="form-group col-xs-12 floating-label-form-group controls">
              <label>Business Name</label>
              <input type="text" class="form-control" placeholder="Business Name*" name="businessName" id="businessName"
                     value="" formControlName="businessName" >
              <div *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)">
                <div *ngIf="businessName.errors.required" class="alert alert-danger">
                  Business name is required.
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="businessName-error" class="error" for="businessName"></label>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Street Address 1</label>
              <input type="text" class="form-control" placeholder="Street Address 1*" name="streetAddress1"
                     id="streetAddress1" value="" formControlName="streetAddress1" >
              <div *ngIf="streetAddress1.invalid && (streetAddress1.dirty || streetAddress1.touched)">
                <div *ngIf="streetAddress1.errors.required" class="alert alert-danger">
                  Street address 1 is required.
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="streetAddress1-error" class="error" for="streetAddress1"></label>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Street Address 2</label>
              <input type="text" class="form-control" placeholder="Street Address 2" name="streetAddress2"
                     id="streetAddress2" formControlName="streetAddress2">
              <p class="help-block text-danger"></p>
            </div>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>City</label>
              <input type="text" class="form-control" placeholder="City*" name="city" id="city" formControlName="city" >
              <div *ngIf="city.invalid && (city.dirty || city.touched)">
                <div *ngIf="city.errors.required" class="alert alert-danger">
                  City is required.
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="city-error" class="error" for="city"></label>
          </div>
          <div class="row control-group">
            <div class="form-group controls">
              <select name="state" id="state" class="form-control" style="margin-bottom: 15px" formControlName="state" >
                <option value="">Select state*</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <div *ngIf="state.invalid && (state.dirty || state.touched)">
                <div *ngIf="state.errors.required" class="alert alert-danger">
                  State is required.
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Zip Code</label>
              <input type="number" class="form-control" placeholder="Zip Code*" name="zipCode" id="zipCode"
                      minlength="5" maxlength="5" formControlName="zipCode">
              <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)">
                <div *ngIf="zipCode.errors.required" class="alert alert-danger">
                  Zip code is required.
                </div>
                <div *ngIf="zipCode.errors.pattern" class="alert alert-danger">
                  Zip code must be 5 digits long.
                </div>

              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="zipCode-error" class="error" for="zipCode"></label>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Phone Number</label>
              <input type="text" class="form-control" placeholder="Phone Number" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber">
              <p class="help-block text-danger"></p>
            </div>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Website</label>
              <input type="text" class="form-control" placeholder="Website" name="website" id="website" formControlName="website">
              <p class="help-block text-danger"></p>
            </div>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>First Name</label>
              <input type="text" class="form-control" placeholder="First Name*" name="firstName" id="firstName" formControlName="firstName" >
              <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                <div *ngIf="firstName.errors.required" class="alert alert-danger">
                  First name is required.
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="firstName-error" class="error" for="firstName"></label>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Last Name</label>
              <input type="text" class="form-control" placeholder="Last Name*" name="lastName" id="lastName" formControlName="lastName" >
              <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                <div *ngIf="lastName.errors.required" class="alert alert-danger">
                  Last name is required.
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="lastName-error" class="error" for="lastName"></label>
          </div>
          <div class="row control-group">
            <div class="form-group col-xs-12 floating-label-form-group controls">
              <label>Email Address</label>
              <input type="email" class="form-control" placeholder="Email Address*" name="email" id="email"
                     formControlName="email" >
              <div *ngIf="email.invalid && (email.dirty || email.touched)">
                <div class="alert alert-danger" *ngIf="email.errors.required">
                  Email is required.
                </div>

                <div class="alert alert-danger">
                  Please enter a valid email
                </div>
              </div>
              <p class="help-block text-danger"></p>
            </div>
            <label id="email-error" class="error" for="email"></label>
          </div>
          <div formGroupName="passwords">
            <div class="row control-group">
              <div class="form-group col-xs-12 floating-label-form-group controls">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password*" name="password" id="password" formControlName="password" />
                <div *ngIf="password.invalid && (password.dirty || password.touched)">
                  <div *ngIf="password.errors.required" class="alert alert-danger">
                    Password is required.
                  </div>
                </div>
                <p class="help-block text-danger"></p>
              </div>
              <label id="password-error" class="error" for="password"></label>
            </div>
            <div class="row control-group">
              <div class="form-group col-xs-12 floating-label-form-group controls">
                <label>Confirm Password</label>
                <input type="password" class="form-control" placeholder="Confirm Password*"  name="confirmPassword" formControlName="confirmPassword" id="confirmPassword" />
                <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                  <div *ngIf="confirmPassword.errors.required" class="alert alert-danger">
                    Password confirmation is required.
                  </div>
                </div>
                <p class="help-block text-danger"></p>
              </div>
              <label id="confirm_password-error" class="error" for="confirmPassword"></label>
            </div>
            <div>
              <div *ngIf="registrationForm.hasError('notSame', 'passwords') && (registrationForm.touched || registrationForm.dirty)" class="alert alert-danger">
                Password and confirmation password do not match
              </div>
            </div>
          </div>
          <div class="modal" style="display: none" id="spinner"><img src="assets/ajax-loader.gif"></div>
          <br>
          <div id="success">
            <div *ngIf="!successfulRegistration && !initialPageInit" class='alert alert-danger'>{{responseMsg}}</div>
            <div *ngIf="successfulRegistration" class='alert alert-success'>{{responseMsg}}</div>
          </div>
          <div class="row">
            <div class="form-group col-xs-12">
              <button [disabled]="!registrationForm.valid || !signUpButtonEnabled" type="submit" class="btn btn-success btn-lg">Sign Up</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- Footer -->
<section id="socialmedia" class="socialmedia">
  <footer class="text-center">
    <div class="footer-above">
      <div class="container">
        <div class="row">
          <div class="footer-col col-md-12">
            <h3>Around the Web</h3>
            <ul class="list-inline">
              <li>
                <a href="https://www.facebook.com/specklesApp/" class="btn-social btn-outline" target="_blank"><i class="fa fa-fw fa-facebook"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/specklesapp/" class="btn-social btn-outline" target="_blank"><i class="fa fa-fw fa-instagram"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/SpecklesApp" class="btn-social btn-outline" target="_blank"><i class="fa fa-fw fa-twitter"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-below">
      <div class="container">
        <div class="footer">
          <a href="#" data-toggle="modal" data-target="#myModal" id="privacy-policy">Privacy Policy</a>
        </div>
        <div class="footer">
          Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries.
          App Store is a service mark of Apple Inc. Google Play and the Google Play logo are trademarks of Google Inc.
        </div>
        <div class="row">
          <div class="col-lg-12">
            Copyright &copy; Speckles {{year}}
          </div>
        </div>
      </div>
    </div>
  </footer>
</section>

<div class="row">
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Privacy Policy</h4>
        </div>
        <div class="modal-body">
          <p><b>What information do we collect?</b></p>
          <p>For businesses: we collect the information you enter in the registration form as well as the specials you post.
            For users: geographic location.</p>

          <p><b>What do we use your information for?</b></p>
          <p>Speckles is a geo-location based app; we use your location data
            to find you the best specials that match your location.
            All information is used solely in the Speckles mobile application.</p>


          <p><b>How do we protect your information?</b></p>
          <p>Our app and website are secured using strong encryption(“https”) and other measures.</p>

          <p><b>Do we disclose your information to third parties?</b></p>
          <p>No.</p>

          <p><b>Your Consent</b></p>
          <p>By using the Speckles website and mobile application, you consent to our Privacy Policy.</p>

          <p><b>Changes to our Privacy Policy</b></p>
          <p>If we modify our Privacy Policy those changes will be posted to this page.
            This page was last modified 27th March 2017.</p>

          <p><b>Contact Us</b></p>
          <p>If you have any questions regarding this Privacy Policy, contact us at: info@getspeckles.com.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Scroll to Top Button (Only visible on small and extra-small screen sizes) -->
<div class="scroll-top page-scroll hidden-sm hidden-xs hidden-lg hidden-md">
  <a class="btn btn-primary" href="#page-top">
    <i class="fa fa-chevron-up"></i>
  </a>
</div>
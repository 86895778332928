import {Component} from '@angular/core';
import {environment} from '../environments/environment';
import {RegistrationModel} from './models/registration.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppService} from './app.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  year = new Date().getFullYear();
  apiUrl = environment.apiUrl;
  registration: RegistrationModel = new RegistrationModel();
  successfulRegistration = false;
  responseMsg = '';
  confirmPasswordValue = '';
  initialPageInit = true;
  signUpButtonEnabled = true;
  passwordGroup = new FormGroup({
      password: new FormControl(this.registration.password, Validators.compose([Validators.required])),
      confirmPassword: new FormControl(this.confirmPasswordValue, Validators.compose([Validators.required]))
  }, {validators: this.verifyPasswords});
  registrationForm = new FormGroup({
      businessName: new FormControl(this.registration.businessName, Validators.compose([Validators.required])),
      streetAddress1: new FormControl(this.registration.streetAddress1, Validators.compose([Validators.required])),
      streetAddress2: new FormControl(this.registration.streetAddress2),
      city: new FormControl(this.registration.city, Validators.compose([Validators.required])),
      state: new FormControl(this.registration.state, Validators.compose([Validators.required])),
      zipCode:  new FormControl(this.registration.zipCode, Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{5}')
      ])),
      phoneNumber: new FormControl(this.registration.phoneNumber),
      website: new FormControl(this.registration.website),
      firstName: new FormControl(this.registration.firstName, Validators.compose([Validators.required])),
      lastName: new FormControl(this.registration.lastName, Validators.compose([Validators.required])),
      email: new FormControl(this.registration.email, Validators.compose([Validators.required, Validators.email])),
      passwords: this.passwordGroup
  });
  constructor(private appService: AppService){}
  verifyPasswords(group: FormGroup){
      const match = group.get('password').value === group.get('confirmPassword').value;
      return match ? null : {notSame: true};
  }
  onSubmit() {
        this.signUpButtonEnabled = false;
        this.registration.businessName = this.registrationForm.value.businessName;
        this.registration.streetAddress1 = this.registrationForm.value.streetAddress1;
        this.registration.streetAddress2 = this.registrationForm.value.streetAddress2;
        this.registration.city = this.registrationForm.value.city;
        this.registration.state = this.registrationForm.value.state;
        this.registration.zipCode = this.registrationForm.value.zipCode;
        this.registration.phoneNumber = this.registrationForm.value.phoneNumber;
        this.registration.website = this.registrationForm.value.website;
        this.registration.firstName = this.registrationForm.value.firstName;
        this.registration.lastName = this.registrationForm.value.lastName;
        this.registration.email = this.registrationForm.value.email;
        this.registration.password = this.registrationForm.value.passwords.password;
        this.initialPageInit = false;
        this.appService.register(this.registration).subscribe(data => {
            const statusCode = data.status;
            this.responseMsg = 'You\'ve been signed up. Please check your email for further details';
            this.successfulRegistration = true;
            this.signUpButtonEnabled = true;
        }, (error: HttpErrorResponse) => {
            const errorStatus = error.status;
            this.successfulRegistration = false;
            this.signUpButtonEnabled = true;
            if (errorStatus === 409) {
                this.responseMsg = 'Error: Already exists';
            } else {
                this.responseMsg = 'Unable to register. Please try again later';
            }
        });
    }
    // in html when we call zipCode.dirty or .touched, the "zipCOde" refers to this method.
    get zipCode() {
      return this.registrationForm.get('zipCode');
    }

    get email() {
        return this.registrationForm.get('email');
    }

    get businessName() {
        return this.registrationForm.get('businessName');
    }
    get streetAddress1() {
      return this.registrationForm.get('streetAddress1');
    }

    get city() {
        return this.registrationForm.get('city');
    }

    get state() {
        return this.registrationForm.get('state');
    }

    get firstName() {
        return this.registrationForm.get('firstName');
    }

    get lastName() {
        return this.registrationForm.get('lastName');
    }

    get password() {
        return this.registrationForm.get('passwords.password');
    }

    get confirmPassword() {
        return this.registrationForm.get('passwords.confirmPassword');
    }

}
